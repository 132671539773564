<template>
  <div class="user-box second-check">
    <search-form @search="searchList"></search-form>
    <renewal-table :list="list" @view="handleView" :currentPage="currentPage"></renewal-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import searchForm from "../components/search-form";
import renewalTable from "./components/renewal-table";

import service from "./api";
import pagination from "@/mixins/pagination";
export default {
  name: "renewal",
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
    };
  },
  components: { searchForm, renewalTable },
  methods: {
    getList(params) {
      // 切换分页当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service
        .table({ page: this.currentPage, ...params })
        .then((res) => {
          let { page = {}, list = [] } = res;
          this.page = page;
          list.forEach((item, index) => {
            item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          });
          this.list = list;
        });
    },
    searchList(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    }
  },
  mounted() {
    this.getList({ pageNum: 1 });
  },
};
</script>
<style></style>
